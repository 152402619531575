<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title=this.pageTitle
        :subtitle=this.pageSubTitle
        :hide-buttons="true"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input id="name" name="name" v-model="name" class="w-full" v-validate="'required'"/>
          <span class="text-danger">{{ errors.first("name") }}</span>
        </div>
      </div>

      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full">
          <label for="periodicity" class="w-full select-large">Frecuencia</label>
          <v-select
              id="periodicity"
              name="periodicity"
              label="name"
              index="id"
              :options="periodicities"
              :placeholder="'Seleccione...'"
              :searchable="true"
              v-model="periodicity"
              v-validate="'required'"
              class="w-full"
          />
          <span v-show="errors.has('periodicity')" class="text-danger">{{ errors.first("periodicity") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="execution_hour" class="w-full select-large">Hora de ejecución</label>
          <flat-pickr
              :config="configDateTimePicker"
              id="execution_hour" name="execution_hour" placeholder="Hora de ejecución"
              v-model="execution_hour"
              v-validate="'required'"
              class="w-full flatpickr flatpickr-input"
          />
          <span class="text-danger">{{ errors.first("execution_hour") }}</span>
          <br/>
        </div>
      </div>
      <div v-if="showDates" class="vx-row ml-2 mb-10">
        <div class="vx-col w-full">
          <label class="w-full select-large">Días</label>
          <div class="centerx">
            <ul class="vx-row">
              <li class="md:w-1/12 mt-1" v-for="(n, index) in 31" :key="index">
                <vs-checkbox v-model="dates" :vs-value="n">{{ n }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-row pt-10">
        <div class="vx-col w-full">
          <vs-table
              :color="'secondary'"
              multiple
              v-model="recipients"
              :data="entry_recipients"
          >
            <template slot="thead">
              <vs-th>
                Todos los destinatarios <span class="text-danger" v-if="!recipients_selected">* seleccione destinatarios</span>
              </vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :state="null">
                <vs-td :data="data[indextr].recipient">
                  {{ data[indextr].recipient }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToScheduledReportsList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateReport" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import NotificationSettingsClient from "../../utils/notificationSettingsClient";
  import Notifier from "./../../utils/notification";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
  const requiredFields = ["name", "periodicity", "execution_hour", "recipients"];

  let dict = requiredFields.reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue] = {
          required: "* Este campo es requerido",
        };
        return previousValue
      },
      {}
  );

  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        pageTitle: "Reporte Programado",
        entryTypeList:{
          "assets": {
            "name": "Activos Eventuales",
            "entry_type": "assets"
          },
          "static-pages": {
            "name": "Páginas estáticas",
            "entry_type": "static-pages"
          },
          "jobs": {
            "name": "Plazas Disponibles",
            "entry_type": "jobs"
          },
          "promotions": {
            "name": "Promociones",
            "entry_type": "promotions"
          },
          "products": {
            "name": "Productos",
            "entry_type": "products"
          },
          "projects": {
            "name": "Proyectos Inmobiliarios",
            "entry_type": "projects"
          }
        },
        entry_recipients :[],
        pageSubTitle: "",
        recipients_selected: true,
        configDateTimePicker: {
          minuteIncrement: 0,
          defaultHour: 0,
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:00",
        },
        periodicityMapper: {
          every_monday: "Todos los lunes",
          every_tuesday: "Todos los martes",
          every_wednesday: "Todos los miércoles",
          every_thursday: "Todos los jueves",
          every_friday: "Todos los viernes",
          every_saturday: "Todos los sábados",
          every_sunday: "Todos los domingos",
          first_day_of_each_month: "El primer día de cada mes",
          last_day_of_each_month: "El último día de cada mes",
          specific_dates: "Fechas personalizadas",
        },
        periodicities: [
          {id: "every_monday", name: "Todos los lunes"},
          {id: "every_tuesday", name: "Todos los martes"},
          {id: "every_wednesday", name: "Todos los miércoles"},
          {id: "every_thursday", name: "Todos los jueves"},
          {id: "every_friday", name: "Todos los viernes"},
          {id: "every_saturday", name: "Todos los sábados"},
          {id: "every_sunday", name: "Todos los domingos"},
          {id: "first_day_of_each_month", name: "El primer día de cada mes"},
          {id: "last_day_of_each_month", name: "El último día de cada mes"},
          {id: "specific_dates", name: "Fechas personalizadas"},
        ],
        temporal_recipients:[],
        id: null,
        name: "",
        periodicity: null,
        recipients: [],
        execution_hour: "00:00",
        dates: [],
        notification_method:null
      };
    },

    mounted() {
      this.setPageTitle()
      this.getModuleRecipients().then(() => {
        if (this.$route.params.id !== undefined) {
          this.$vs.loading();
          this.loadReportData().then(() => {
            this.$vs.loading.close();
          });
        }
      })
    },

    computed: {
      validateForm() {
        return !this.errors.any()
            && requiredFields.reduce((accumulator, currentValue) => {
              return accumulator && this.currentValue !== null;
            });
      },

      showDates() {
        if(this.periodicity !== null){
          return this.periodicity.id === "specific_dates";
        }
        return false;
      }
    },

    methods: {
      setPageTitle() {
        let entry_type = this.$route.params["entry_type"]
        let val = this.entryTypeList[entry_type]
        this.pageTitle = `Crear Reporte Programado: ${val.name}`
      },

      async getModuleRecipients(){
        await NOTIFICATION_SETTINGS_CLIENT.all(this.$route.params.entry_type )
            .then(response => {
              this.entry_recipients = response.data;
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
      },

      async loadReportData() {
        await NOTIFICATION_SETTINGS_CLIENT.retrieve_scheduled_report(
            this.$route.params.entry_type, this.$route.params.id
        )
            .then(response => {
              this.id = response.data.id;
              this.name = response.data.name;

              if(response.data.notification_method === "all-recipients"){
                this.entry_recipients.forEach(item =>  this.recipients.push(item))
              }else{
                this.entry_recipients.forEach(item => {
                  if (response.data.recipients !== undefined && response.data.recipients.includes(item.recipient)){
                    this.recipients.push(item)
                  }
                })
              }

              this.periodicity = {
                id: response.data.periodicity,
                name: this.periodicityMapper[response.data.periodicity]
              }
              this.execution_hour = `${response.data.execution_hour.toString().padStart(2, "0")}:00`;

              if(response.data.dates !== null && response.data.dates !== undefined){
                this.dates = response.data.dates;
              }
            })
            .catch(error => {
              notifier.notification("error");
            });
      },

      async createOrUpdateReport() {
        let status_validation = true

        await this.$validator.validateAll().then(el => {status_validation = el})

        if(!status_validation) return false

        if(this.recipients.length > 0 ){
          this.recipients_selected = true
        }else{
          this.recipients_selected = false;
          return false
        }

        let data = this.prepareData()

        this.$vs.loading();

        if (this.id !== null && this.id !== undefined && this.id !== "") {
          await NOTIFICATION_SETTINGS_CLIENT.update_scheduled_report(
              data, this.$route.params.entry_type, this.$route.params.id
          )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("updated");
                this.goToScheduledReportsList();
              })
              .catch(error => {
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        } else {
          await NOTIFICATION_SETTINGS_CLIENT.create_scheduled_report(data, this.$route.params.entry_type )
              .then(response => {
                this.$vs.loading.close();
                notifier.notification("created");
                this.goToScheduledReportsList();
              })
              .catch(error => {
                this.$vs.loading.close();
                notifier.alertMessage("error");
              });
        }
      },

      goToScheduledReportsList() {
        let routeName = "list-scheduled-report-notifications-settings"
        this.$router.push({name: routeName, params: {entry_type: this.$route.params.entry_type}});
      },

      prepareData(){
        let data = {
          name: this.name,
          periodicity: this.periodicity.id,
          execution_hour: parseInt(this.execution_hour.split(":")[0]),
        }

        if(this.periodicity.id === "specific_dates"){
          data.dates = this.dates;
        }

        if (this.entry_recipients.length === this.recipients.length){
          data.notification_method = "all-recipients"
        }else{
          data.recipients = this.recipients.map(record => record.recipient)
          data.notification_method = "custom-recipients"
        }

        return data
      }
    },

    components: {
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>

<style scoped>
  .recipients-list {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>